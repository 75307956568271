<template>

  <body>
    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">

        <!-- Header Section -->
        <Front-Header> </Front-Header>



        <!-- Main Start-->
        <section class="hero_section height-half text-center">
          <img :src="pages.image" style="position: absolute;" :alt="pages.title">
          <div class="container">
            <h1 data-aos="fade-right" data-aos-duration="1200" class="aos-init aos-animate">{{ pages.title }}</h1>
            <p data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200" class="aos-init aos-animate">{{
            pages.short_description
            }}</p>
          </div>
        </section>

        <!--Contact-us start-->
        <div id="contactUs">
          <section class="contact_wrapper">
            <div class="container">
              <div class="contact_info">
                <h3 class="title">
                  {{ pages.title }}
                </h3>

                <ul class="icons_wrapp pl-0">
                  <li class="d-flex">
                    <div class="icon">
                      <i class="fas fa-map-marker-alt mr-2"></i>
                    </div>
                    <div class="text">
                      {{ address.mapLocation }}
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fas fa-envelope mr-2"></i>
                    </div>
                    <div class="text">
                      {{ address.email }}
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-phone-alt mr-2"></i>
                    </div>
                    <div class="text">
                      {{ address.phoneNumber }}
                    </div>
                  </li>
                </ul>

                <ul class="soci_wrap pl-0">
                  <li>
                    <a v-bind:href="'' + sociallinks.facebook + ''" target="_blank">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a v-bind:href="'' + sociallinks.twitter + ''" target="_blank">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a v-bind:href="'' + sociallinks.instagram + ''" target="_blank">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="contact_msg">
                <h3 class="title">
                  Send a Message
                </h3>
                <validation-observer ref="simpleRules">
                  <form class="form-horizontal form-material" v-on:submit.prevent="validationForm">
                    <div class="form_fild">
                      <div class="input_group w_50">
                        <validation-provider name="First Name" rules="required|max:20" #default="{ errors }">
                          <input placeholder="First Name" type="text" class="input" v-model.trim="first_name"
                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                      <div class="input_group w_50">
                        <validation-provider name="Last Name" rules="required|max:20" #default="{ errors }">
                          <input placeholder="Last Name" type="text" class="input" v-model.trim="last_name"
                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                      <div class="input_group w_50">
                        <validation-provider name="Email Address" rules="required|email|max:50" #default="{ errors }">
                          <input placeholder="Emal Address" type="text" class="input" v-model.trim="email"
                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                      <div class="input_group w_50">
                        <validation-provider name="Phone Number" rules="required|max:20" #default="{ errors }">
                          <input placeholder="Phone Number" type="tel" class="input" v-model.trim="phone_no"
                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                      <div class="input_group w-100">
                        <validation-provider name="Query" rules="required" #default="{ errors }">
                          <select @change="selectCategory($event)" name="query" id="sel"
                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" v-model="subject">
                            <option value="">Select query</option>
                            <option value="6zGYBwseupcZMmLnBXxl">Billing and payment support</option>
                            <option value="LLDi2b6dpBfdglXm71dj">General queries and help</option>
                            <option value="UjSO400vO0D8Pgu3qkNT">Press and Meadia inquiries</option>
                            <option value="z0FnmzSpFP4sEopHNz4w">Business inquiries</option>
                          </select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                      <div class="input_group w-100">
                        <validation-provider name="Description" rules="required|max:1000" #default="{ errors }">
                          <textarea placeholder="Write your message here..." class="input input_textarea" rows="6"
                            v-model.trim="message" :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"></textarea>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                      <div class="input_group">
                        <input type="submit" class="btn" value="Send">
                      </div>

                    </div>
                  </form>
                </validation-observer>
              </div>

            </div>
          </section>
        </div>
        <section>
          <div class="container" v-if="this.subscriptions.length">
            <div class="text-center">
              <h2 class="mb-10">Check Your Subscription Cancelation Request</h2>

              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link" :class="{ 'active': toggledOp == 1 }" data-toggle="tab"
                    @click="toggleOptions(1)">Subscriptions Cancelation Request</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" :class="{ 'active': toggledOp == 2 }" data-toggle="tab"
                    @click="toggleOptions(2)">Cancele Subscriptions Status</a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="nav-link" :class="{ 'active': toggledOp == 3 }" data-toggle="tab"
                    @click="toggleOptions(3)">My Offers</a>
                </li> -->
              </ul>

              <!-- Tab panes -->
              <div class="tab-content">
                <div class="tab-pane container" :class="{ 'active': toggledOp == 1 }">
                  <!-- <div style="text-align: center;">
                    <h4>Click For Subscription Cancelation Request</h4>
                  </div>
                  <div class="section-btn"> -->
                  <!-- <h4>Hello</h4> -->
                  <!-- <button class="subscription-btn btn btn-primary" @click="isShowing ^= true">Cancel
                      Subscriptions</button>
                  </div> -->
                  <div class="prfInformation">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="st_holder validation">
                            <div class="form_holder">
                              <div class="forgot-info info-div" style="display: none">
                                <p class="show-error"></p>
                              </div>
                              <div id="errors_key" style="color: red; display: none"></div>
                              <validation-observer ref="simpleRulesForCancle">
                                <form id="wl-login" class="form-vertical" autocomplete="off" novalidate="novalidate"
                                  v-on:submit.prevent="validationFormSubcription">
                                  <input type="hidden" name="_csrf"
                                    value="R3M1R2d4a2soPwR/CjQHUiA8DXRVGg05KisFLjU3GB8BMlYPNQwxIw==" />
                                  <fieldset>
                                    <div class="cn_block inf_holder">
                                      <div class="title_head">
                                        <h2>Subscription Cancelation Request</h2>
                                      </div>
                                      <div class="wrapFields">
                                        <div class="fl_row">
                                          <div class="form-group">
                                            <label for="reason">Reason</label>
                                            <div class="select_holder">
                                              <validation-provider name="Reason" rules="required|max:1000"
                                                #default="{ errors }">
                                                <input name="reason" placeholder="Enter Reason For Cancelation"
                                                  id="reason" class="input_field" type="text" v-model="reason" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="fl_row">
                                          <div class="form-group">
                                            <label for="subscription_name">Select Cancel Subscription</label>
                                            <div class="select_holder">
                                              <validation-provider name="Select Cancel Subscription" rules="required"
                                                #default="{ errors }">
                                                <select id="subscription_name" name="subscription_name"
                                                  v-model="subscription_name" aria-required="true" aria-invalid="false"
                                                  class="input_field">
                                                  <option value="">Select select subscription for cancel</option>
                                                  <option v-for="subscriptions_data in subscriptions"
                                                    :value="subscriptions_data.value">{{subscriptions_data.text}}
                                                  </option>
                                                </select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="fl_row">
                                          <input type="submit" class="link btnWhite btn btn-primary"
                                            value="Cancelation Request" style="margin-top: 30px;margin: auto;" />
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                </form>
                              </validation-observer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="tab-pane container" :class="{ 'active': toggledOp == 2 }">
                  <div class="prfInformation">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="st_holder validation">
                            <div class="form_holder">
                              <div class="forgot-info info-div" style="display: none">
                                <p class="show-error"></p>
                              </div>
                              <div id="errors_key" style="color: red; display: none"></div>
                              <validation-observer ref="simpleRules">
                                <form id="wl-login" class="form-vertical" autocomplete="off" novalidate="novalidate"
                                  v-on:submit.prevent="validationForm">
                                  <input type="hidden" name="_csrf"
                                    value="R3M1R2d4a2soPwR/CjQHUiA8DXRVGg05KisFLjU3GB8BMlYPNQwxIw==" />
                                  <fieldset>
                                    <div class="cn_block inf_holder">
                                      <div class="title_head">
                                        <h2>Subscription Information</h2>
                                      </div>
                                      <div class="wrapFields">
                                        <div class="fl_row">
                                          <input type="submit" class="link btnWhite btn btn-primary"
                                            value="Cancelation Request" style="margin-top: 30px;margin: auto;" />
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                </form>
                              </validation-observer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </section>
        <!-- Main End-->

        <!-- Footer Section -->
        <Front-Footer> </Front-Footer>

      </div>
    </div>

  </body>
</template>
<script>
import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)
import { db } from '@/main'
import {
  BFormFile,
} from 'bootstrap-vue'

import store from '@/store'
// import { required, email } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  required, email, confirmed, password, image, size
} from '@validations'

import axios from 'axios'

export default {
  components: {
    'Front-Header': Header,
    'Front-Footer': Footer,
    'Loader': Loader,
    ValidationProvider,
    ValidationObserver,
    validate,
    required,
    email,
    confirmed,
    password,
    image,
    size,
    BFormFile
  },
  data() {
    return {
      toggledOp: 0,
      isShowing: false,
      pages: {},
      address: {},
      users: {},
      sociallinks: {},
      reason: '',
      subscription_name: '',
      addresss: '',
      emails: '',
      phones: '',
      subject: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '',
      emailm: '',
      emailname: '',
      message: '',
      meta_title: '',
      meta_description: '',
      subscriptions: [],
    }
  },

  metaInfo() {
    //alert();
    return {
      title: this.meta_title,
      meta: [
        { name: 'description', content: this.meta_description },
      ]
    }
  },

  created: function () {
    this.fetchPages();
    this.getUser();
    this.fetchSubscriptions();
    // this.submitCancelationSubcription();
  },

  methods: {

    toggleOptions(val) {
      this.toggledOp = val;
    },

    selectCategory(event) {
      this.subject = event.target.value;
      // alert(this.subject);
    },

    validationForm() {
      // alert()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submit();
        }
      })
    },

    validationFormSubcription() {

      this.$refs.simpleRulesForCancle.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitCancelationSubcription();
        }
      })
    },

    fetchSubscriptions() {
      var id = localStorage.getItem("userSessionId");
      /* debuf */
      db.collection("order_details")
        .where("uid", "==", id)
        //.orderBy("created", "desc")
        .get()
        .then((querySnapshot) => {
          // console.log('hhhh');

          querySnapshot.forEach((doc1) => {

            // console.log(doc1.id, " => ", doc1.data());

            db.collection("subscriptions")
              // .where("delete_id", "!=", 1)
              .get()
              .then((querySnapshot) => {
                // console.log('hhhh');

                querySnapshot.forEach((doc) => {

                  // console.log("doc1.data().subscriptions");
                  // console.log(doc1.data().subscription);
                  // console.log(doc.id);
                  if (doc1.data().subscription == doc.id && doc1.data().delete_id != 1) {
                    var discountPrice = doc.data().price - doc.data().price * doc.data().discount / 100;
                    var vat = discountPrice + discountPrice * doc.data().vat / 100;
                    this.subscriptions.push({
                      value: doc.id,
                      text: doc.data().title,
                      id: doc.id,
                      title: doc.data().title,
                      tag_line: doc.data().tag_line,
                      tags: doc.data().tag,
                      description: doc.data().description,
                      image: doc.data().image,
                      price: doc.data().price,
                      discount: doc.data().discount,
                      discountPrice: discountPrice,
                      vat: vat,
                      created: doc.data().created,
                    });
                  }
                  console.log(doc.id, " => ", doc.data());

                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
            /*tsy */


          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      /* debuf */


    },

    submitCancelationSubcription() {
      var i = 0;
      this.CancelSubData = [];
      // console.log(this.reason)
      store.dispatch('app/commitActivateLoader');
      var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
      var uid = localStorage.getItem("userSessionId");
      db.collection("cancle_subscription")
        .where("user_id", "==", uid)
        .where("product_id", "==", this.subscription_name)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.CancelSubData.push({
              index: i,
              id: doc.id,
              product: doc.data().product_id,
              discount_type: doc.data().reason,
              user_id: doc.data().user_id,
              created: doc.data().created,
              modified: doc.data().modified,
              status: doc.data().status ?? 1
            });
            console.log(this.CancelSubData)
          })
          console.log(i)
          if (this.CancelSubData[i]) {
            console.log(this.CancelSubData[i].product)
            console.log(this.subscription_name)
            if (this.CancelSubData[i].product == this.subscription_name && !this.CancelSubData[i].status) {
              store.dispatch('app/commitDeactivateLoader');
              this.showNotification('Error! You Have Already Submit Cancelation Request For This Subscription!', 'AlertTriangleIcon', 'danger');
            }else if (this.CancelSubData[i].product == this.subscription_name && this.CancelSubData[i].status == 1){
              db.collection("cancle_subscription")
              .doc(this.CancelSubData[i].id)
              .update({
                user_id: uid,
                product_id: this.subscription_name,
                reason: this.reason,
                status: 0,
                created: date,
                modified: date,
              })
              .then((doc) => {
                console.log("Updated Successfully!");
                store.dispatch('app/commitDeactivateLoader');
                this.showNotification('Success, Request Send SuccessFully!', 'AlertTriangleIcon', 'success');
                // this.$router.push({ name: 'affiliate-sharing-list' });
              })
            }
          } else {
            db.collection("cancle_subscription")
              .add({
                user_id: uid,
                product_id: this.subscription_name,
                reason: this.reason,
                created: date,
                modified: date,
              })
              .then((doc) => {
                console.log("Added Successfully!");
                store.dispatch('app/commitDeactivateLoader');
                this.showNotification('Success, Request Send SuccessFully!', 'AlertTriangleIcon', 'success');
                // this.$router.push({ name: 'affiliate-sharing-list' });
              })
          }
          i++;
        })
        // store.dispatch('app/commitDeactivateLoader');
    },

    submit() {

      var date = new Date().toLocaleString()

      db.collection("email_management")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            // console.log("doc.data()");
            if (doc.id == this.subject) {
              this.emailm = doc.data().email;
              this.emailname = doc.data().Name;
            }

          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          this.showNotification('Error! Request Failed!', 'AlertTriangleIcon', 'danger');

        });


      //var  date = new Date().toLocaleString();//.toISOString().slice(0, 10);  
      db.collection("contact_us")
        .add({
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          subject: this.emailname,
          phone_no: this.phone_no,
          message: this.message,
          created: date,
          modified: date,
        })
        .then(() => {
          this.$router.push('/thankyou?id=' + this.subject);
          console.log("Added Successfully!");
          this.showNotification('Success, Thank you for contact!', 'AlertTriangleIcon', 'success');


          var axios = require('axios');
          var FormData = require('form-data');
          var data = new FormData();
          data.append(' first_name', this.first_name);
          data.append('last_name', this.last_name);
          data.append('email', this.email);
          data.append('emailm', this.emailm);
          data.append('phone_no', this.phone_no);
          data.append('subject', this.emailname);
          data.append('message', this.message);

          var config = {
            method: 'post', url: this.$VUE_APP_API_ENDPOINT + 'conatct_us', headers: { 'Content-Type': 'multipart/form-data' }, data: data
          };

          axios(config)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });


        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });


      // POST request using axios with set headers
      const data = {
        "email": "admin@gmail.com",
        "password": "admin@gmail.com",
      };
      axios.post("http://159.65.231.72/api/contacts_user", data)
        .then((response) => {
          console.warn(response);
          this.$toasted.success('Success, Sent Successfully!', {
            icon: 'check',
            //icon : 'error_outline'
          })

        });
    },


    getUser() {
      db.collection("users").doc('6zoxAapxwxfb3Nu8H8tl')
        .get()
        .then((doc) => {
          // console.log('hhhh99');
          //console.log(doc.id, " => ", doc.data());
          this.users = doc.data();

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

    },

    fetchPages() {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      db.collection("pages").doc('W3sk77Lpp6GYAS2RXM6F')
        .get()
        .then((doc) => {
          // console.log('hhhh88');
          console.log(doc.id, " => ", doc.data().description);
          this.pages = doc.data();
          // console.log(doc.data().description.split("|"));
          this.addresss = doc.data().description.split("|")[0].split(">")[1];
          this.emails = doc.data().description.split("|")[1];
          this.phones = doc.data().description.split("|")[2].split("<")[0];
          this.meta_title = doc.data().meta_title;
          this.meta_description = doc.data().meta_description;

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      db.collection("sociallinks").doc('ot3idgvr902v0m6ynLEz')
        .get()
        .then((doc) => {
          //console.log('hhhh55');
          //console.log(doc.id, " => ", doc.data());
          this.sociallinks = doc.data();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      db.collection("address").doc('UukY8WLJp0Bhn12B4mJB')
        .get()
        .then((doc) => {
          // console.log('hhhh55');
          // console.log(doc.id, " => ", doc.data());
          this.address = doc.data();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  }
}

</script>
<style>
#sel {
  color: #c1bbb6;
}

.contact_wrapper form.form-horizontal.form-material {
  max-height: initial !important;
  overflow-y: visible;
}

.contact_wrapper form.form-horizontal.form-material .invalid-feedback {
  margin-top: 20px;
  position: absolute;
  height: 50px;
  z-index: 55;
  top: 24px;
  font-size: 12px;
}

#contactUs input.input:not(:focus)+.placeholder {
  display: none !important;
}

#contactUs select {
  color: #444;
}

.section-btn {
  text-align: center;
  margin: 3%;
}

.subscription-btn {
  background-color: #03e1bc !important;
  border-color: #03e1bc !important;
  box-shadow: unset !important;
  font-size: 18px !important;
}

span {
  width: 100%;
}

[dir] .prfInformation .st_holder .inf_holder .fl_row .input_field {
  margin-bottom: 2px;
}

.nav-tabs {
  margin-bottom: 45px;
  border: none;
  background: #f4f4f4;
  /* padding: 8px; */
  border-radius: 30px;
  justify-content: center;
  display: inline-flex;
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs a {
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
}

.nav-tabs li a.active {
  background: #03e1bc !important;
}
</style>
